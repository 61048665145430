@if (availableYears().length <= 1) {
  <a
    (click)="downloadCalendar(downloadCalendarUrl$() + '')"
    class="button button--subtle button-outline button--secondary ms-auto"
    [ngClass]="cssClass()"
  >
    <span>{{ 'download-inzamelkalender-knoptekst' | fromDictionary }}</span>
    <fstr-icon anchor="icon-download"></fstr-icon>
  </a>
} @else {
  <button [matMenuTriggerFor]="pdfMenu" class="button button--subtle button-outline button--secondary" [ngClass]="cssClass()">
    <span>{{ 'download-inzamelkalender-knoptekst' | fromDictionary }}</span>
    <fstr-icon anchor="icon-chevron-down"></fstr-icon>
  </button>
  <mat-menu #pdfMenu="matMenu" xPosition="before">
    <a
      class="button"
      mat-menu-item
      (click)="downloadCalendar(downloadCalendarUrl$() + '&year=' + year)"
      *ngFor="let year of availableYears()"
    >
      Inzamelkalender {{ year }}
    </a>
  </mat-menu>
}
