import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { Browser } from '@capacitor/browser';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';
import { Address } from 'src/app/addresses';

@Component({
  selector: 'app-calendar-download-button',
  standalone: true,
  imports: [DictionaryNgrxModule, IconModule, MatMenuModule, CommonModule],
  templateUrl: './calendar-download-button.component.html',
  styleUrl: './calendar-download-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarDownloadButtonComponent {
  availableYears = input.required<number[]>();
  address = input.required<Address | null>();
  cssClass = input<string>('d-md-flex d-none');

  downloadCalendarUrl$ = computed(() => {
    if (!this.address()) {
      return {};
    }

    const { postalCodeFull, houseNumber, addition } = this.address()!;
    return `https://www.rova.nl/api/waste-calendar/year/pdf?postalcode=${postalCodeFull}&houseNumber=${houseNumber}&addition=${addition || ''}`;
  });

  async downloadCalendar(url: string) {
    await Browser.open({ url });
  }
}
